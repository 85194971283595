@media (max-width:600px) and (min-width:200px) {

    .main-balance-btn .refresh-btn,
    .xs-bets,
    .xs-setting,
    header button.btn-signup {
        box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 40%)
    }

    .search-xs,
    .xs-game-name {
        float: right
    }

    .popup-setting-body .userCoin,
    body,
    html {
        font-family: Helvetica, Tahoma, sans-serif
    }

    .nointernet {
        height: 100vh
    }

    .fancy-bet .bets-selections dt .btn-book {
        position: absolute;
        right: 1.33333vw;
        width: 10.66667vw;
        font-weight: 400;
        left: 140px;
        top: 7px
    }

    #valcodeid,
    .acceptodds input,
    .bets-selections .mode-land,
    .bets-selections a.x-none,
    .bets-selections-head .mode-land,
    .close-saga,
    .pin-toggle input,
    .sport-xs-tab span.MuiTabs-indicator,
    .trade .mode-land,
    .trade-2 .mode-land {
        display: none
    }

    :root {
        --darkblue: #273e4e;
        --white: #ffffff
    }

    .login-index .MuiButton-label {
        width: auto
    }

    .dashboard>div {
        margin-top: 0;
        width: 98% !important
    }

    #root .headerInplay {
        background-color: #172832 !important;
        width: 100% !important;
        border: none;
        margin: 0;
        border-radius: 0
    }

    .headerInplay>.MuiTabs-flexContainer {
        border: 1px solid #fff;
        border-radius: 0
    }

    header .btn-signup,
    header .login-index {
        width: 28.8vw;
        height: 8.53333vw;
        border: .26667vw solid #710b0b;
        box-shadow: inset 0 .26667vw 0 0 rgb(255 255 255 / 50%);
        line-height: 8.53333vw;
        padding: 0;
        color: #fff;
        border-radius: 1.06667vw;
        margin-top: .26667vw;
        position: relative;
        top: -3px
    }

    .header>header>div,
    .tab>div,
    .xp-0 {
        padding: 0 !important
    }

    header .btn-signup {
        display: none;
        width: 20vw;
        margin-right: 1.06667vw;
        background-image: linear-gradient(-180deg, #666 0, #333 100%);
        border-color: #000
    }

    header button.btn-signup {
        border-color: #000;
        color: #ffb80c;
        background-color: rgba(51, 51, 51, .4);
        display: flex;
        margin-top: 0
    }

    header .login-index {
        color: #fff;
        background-image: linear-gradient(180deg, #fb3434 0, #e80505 100%);
        display: flex;
        margin-left: 0;
        margin-top: 0
    }

    header {
        padding: 2.66667vw 1.86667vw
    }

    header .login-index:before {
        content: "";
        width: 6.53333vw;
        height: 4.53333vw;
        background: url(../images/userimage.svg) center/contain no-repeat;
        margin-right: 0;
        vertical-align: top
    }

    .xs-none {
        display: none !important
    }

    .md-none,
    .stake-custom-edit input,
    .xs-sport-tab {
        display: block !important
    }

    .md-tbl-none {
        display: table-header-group !important
    }

    .mb-footer {
        margin-bottom: 70px
    }

    .prx-4 {
        padding-right: 4px !important
    }

    .plx-4 {
        padding-left: 4px !important
    }

    .dashboard>div.dashborad-slider {
        width: 100% !important;
        margin: 0;
        border-top: 1px solid #000
    }

    .header>header>div {
        min-height: auto
    }

    .right-login {
        margin: 0;
        padding-right: 12px
    }

    .login-header {
        height: 280px;
        background-image: url('../images/login-bg1.png');
        background-size: 100% 100%;
        text-align: center;
        display: flex;
        align-items: center;
        flex-flow: column;
        bottom: 30px;
        justify-content: flex-end
    }

    .login-section {
        /* background-image: linear-gradient(56deg,#3a5870 4%,#1d2c38 42%); */
        background-image: linear-gradient(56deg, #ffcc2e 4%, #ffb80c 42%);
        height: 100vh
    }

    .login-header img {
        width: 130px;
        margin: 0 auto
    }

    .login-header p {
        margin: 0;
        color: #fff;
        font-size: 9.2px
    }

    .login-header>div {
        width: 100px;
        margin-top: 28px;
        margin-bottom: 8px;
        opacity: .5
    }

    .acceptodds label,
    .bottom-nav a,
    .login-header>div>img,
    .popup-setting-body .stake-custom-edit .col-stake_edit,
    .xs-account-tabs>div {
        width: 100%
    }

    .login-form {
        width: 84%;
        margin: 36px auto 0
    }

    .login-form input {
        border: 1px solid #666 !important
    }

    .validation-input img {
        position: absolute;
        width: 55px;
        right: 4px;
        top: 40%;
        transform: translate(0, -50%)
    }

    .footer-links {
        width: 100vw;
        margin: 3.2vw auto 8vw;
        font-size: 14px;
        text-align: center;
        padding: 0 0.86667vw
    }

    .footer-links a:first-child {
        border: none;
        padding-left: 0
    }

    .footer-links a {
        color: rgba(0,0,0,.7);
        text-decoration: underline;
        margin-bottom: 1.33333vw;
        display: inline-block
    }

    .footer-links a:first-child:before {
        margin: 0;
        content: ""
    }

    .footer-links a:before {
        margin: 0 .4vw;
        content: "|"
    }

    .footer-bottom {
        margin: 6.4vw auto 70px
    }

    .social {
        background: #ffffff80
    }

    .login-social button,
    .social .MuiTabs-root button {
        padding: 6px
    }

    .xs-down-app {
        width: 100%;
        display: flex;
        justify-content: center
    }

    .xs-down-app>div:first-child {
        margin-right: 2.66667vw;
        margin-bottom: 2.66667vw
    }

    .xs-down-app p {
        text-align: left;
        margin: 0 !important;
        font-size: 2.66667vw
    }

    .tab-description>div {
        padding: 3.46667vw 0 2.66667vw;
        background: #00000094;
    }

    .tab-description>div p {
        font-size: 11.9px
    }

    .xs-down-app>div:first-child>div {
        background: #ffffff4d;
        border: 1px solid #0000004d;
        padding: .8vw 1.86667vw 1.6vw;
        border-radius: 2.13333vw;
        color: #00000080;
        height: 8.8vw;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .xs-down-app img {
        width: 140px
    }

    .xs-down-app .bet-img {
        width: 26.66667vw;
        height: 4.26667vw;
        opacity: .5
    }

    .login-social .tab-description>div a {
        display: block;
        color: #efefef;
        padding: 4px 8px;
        font-size: 12px
    }

    .login-social {
        background: linear-gradient(56deg, #ffb80c 4%, #ffb80c 42%)
    }

    .login-social .MuiTabs-root div {
        background: #00000059
    }

    .login-social .MuiTab-textColorPrimary {
        color: #999
    }

    .login-social .MuiTab-textColorPrimary.Mui-selected {
        color: #efefef
    }

    .login-social .MuiTab-textColorPrimary.Mui-selected::after {
        content: '';
        position: absolute;
        top: 100%;
        height: 2.66667vw;
        background-image: url(https://bxawscf.Exchange/images/mobile/support_arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: initial;
        pointer-events: none
    }

    .login-close {
        position: fixed;
        right: 1.86667vw;
        justify-content: center;
        align-items: center;
        width: 9.33333vw;
        height: 9.33333vw;
        background-color: rgba(0, 0, 0, .7);
        border-radius: 50%;
        margin-top: 1.86667vw;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .login-close svg {
        color: #fff
    }

    .logo {
        width: 33.8vw
    }

    .bottom-nav {
        position: fixed;
        bottom: 0;
        z-index: 99;
        width: 100%
    }

    .bottom-nav button {
        background-image: linear-gradient(-180deg, #243a48 20%, #172732 91%);
        color: #fff !important;
        border: 0;
        min-width: 20vw;
        padding: 1.86667vw 2.66667vw 0;
        width: 100%;
        min-height: 13.33333vw;
        align-items: flex-start;
        top: -1px
    }

    .bottom-nav button.Mui-selected {
        background: linear-gradient(-180deg, #32617f 20%, #1f4258 91%)
    }

    .bottom-nav button.home::before,
    .news-section h4::after {
        background-repeat: no-repeat;
        position: absolute;
        content: ''
    }

    .bottom-nav>div {
        height: 46px
    }

    .bottom-nav button.home img {
        width: 8.53333vw;
        height: 8vw;
        margin: -2.13333vw auto 0
    }

    .bottom-nav button.sportlable img {
        width: 5.86667vw;
        height: 4.8vw
    }

    .bottom-nav button img {
        width: 5.53333vw;
        height: 5.53333vw
    }

    .bottom-nav button .MuiBottomNavigationAction-label {
        font-size: 3.2vw;
        padding-top: 1px;
        opacity: 1
    }

    .bottom-nav button.home .MuiBottomNavigationAction-label {
        margin-top: 0
    }

    .bottom-nav .home.Mui-selected::before {
        background-image: url('../images/home-menu.svg')
    }

    .bottom-nav button.home::before {
        top: -4.26667vw;
        width: 100%;
        height: 4.53333vw;
        pointer-events: none;
        background-size: 100% 100%;
        z-index: -1;
        background-image: url('../images/home-menu-dark.svg')
    }

    .news-section {
        background-image: linear-gradient(-180deg, #2f424d 0, #141e21 100%);
        height: 6.66667vw;
        display: flex;
        align-items: center
    }

    .news-section h4 {
        font-size: 12px;
        margin: 0;
        color: #fff;
        position: relative;
        z-index: 9;
        display: flex;
        flex-basis: 19.2vw;
        align-items: center;
        justify-content: center;
        height: 6.66667vw
    }

    .inplay-now-xsblock,
    .news-head svg,
    .news-head>h5 {
        font-size: 14px
    }

    .news-head a,
    .news-head>h5 {
        color: #ffb80c;
        line-height: 2.4
    }

    .news-head,
    header>div {
        justify-content: space-between
    }

    .news-section>div {
        width: 78%;
        overflow: hidden
    }

    .news-section img {
        vertical-align: middle;
        width: 4.53333vw;
        height: 4.53333vw;
        margin-right: .53333vw
    }

    .news-section h4::after {
        top: 0;
        z-index: 2;
        width: 1.2994vmax;
        height: 100%;
        right: -1.04948vmax;
        background-image: url('../images/right-arrow.svg');
        background-size: contain;
        margin-top: 0
    }

    .news-section .marquee-container,
    .news-section .marquee-container a,
    .news-section .marquee-container>div {
        --gradient-color: linear-gradient(-180deg, #2F424D 0%, #141E21 100%) !important;
        color: #6ac2ff;
        font-size: 3.46667vw;
        font-weight: 700;
        padding-right: 16px
    }

    .news-section .marquee-container a span {
        line-height: 4vw;
        height: 4vw;
        font-style: italic;
        font-size: 2.93333vw;
        font-weight: 400;
        color: #1c2834;
        background-color: #6ac2ff;
        border-radius: .53333vw;
        padding: 0 1.33333vw;
        margin-right: 1.33333vw
    }

    .news-modal {
        background: #fff;
        width: 92%;
        border-radius: 6px
    }

    .news-head {
        background: #222;
        display: flex;
        align-items: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px
    }

    .news-head>h5 {
        margin: 0;
        padding-left: 8px
    }

    .news-head a {
        padding: 0 2.46667vw;
        border-left: 1px solid #be7809;
        min-height: 34px;
        display: flex;
        align-items: center
    }

    .news-body {
        padding: 4.26667vw 3.46667vw;
        max-height: 200px;
        overflow-y: auto
    }

    .news-block {
        padding-bottom: 6.66667vw;
        padding-top: 4.66667vw;
        border-bottom: 1px solid #e0e6e6
    }

    .news-date {
        width: 18.66667vw;
        margin-bottom: 4.26667vw;
        border-top: 1.06667vw solid #4a4a4a;
        padding-top: 1.06667vw;
        color: #4a4a4a;
        display: flex
    }

    .news-date>span {
        font-size: 8vw;
        line-height: 8.53333vw
    }

    span.inner-date {
        display: flex;
        flex-direction: column;
        font-size: 3.73333vw;
        line-height: 1.2;
        padding-left: 4px;
        text-align: center
    }

    .news-block p {
        margin-top: 0;
        font-size: 14px;
        letter-spacing: .6px
    }

    .news-footer ul {
        display: flex;
        justify-content: center;
        margin: 2.66667vw 0;
        padding: 0
    }

    #open-bets-table .table_first_row .text_left-in-play,
    .sport-block>a,
    button.xs-expo-btn,
    header>div {
        display: inline-block
    }

    .news-footer ul.pages li {
        list-style: none;
        margin-right: .8vw;
        margin-left: .8vw
    }

    .pages a {
        font-weight: 400;
        margin: 0;
        line-height: 9.33333vw;
        padding: 1.6vw 3.2vw;
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw
    }

    .pages a.disable {
        background: #dfdfdf;
        color: #999;
        cursor: not-allowed
    }

    .pages a.select {
        background: #444;
        border-color: #000;
        color: #ffb80c
    }

    button.xs-expo-btn {
        font-size: 11px;
        padding: 0 1.66667vw 0 1.86667vw;
        width: 85%;
        height: 9.59vw;
        line-height: 3.2vw
    }

    button.xs-expo-btn>span.MuiButton-label {
        display: flex;
        flex-direction: column;
        align-items: baseline
    }

    .main-balance-btn button.xs-expo-btn strong {
        font-size: 9.2px;
        margin: 0
    }

    button.xs-expo-btn div {
        line-height: 1.4;
        text-align: left;
        font-size: 9.2px
    }

    button.xs-expo-btn>span>div>span {
        opacity: .7
    }

    button.xs-expo-btn div.nums {
        position: absolute;
        top: 2.4vw;
        right: 1.86667vw;
        height: 3.73333vw;
        font-size: 2.93333vw;
        line-height: 3.73333vw;
        border: .26667vw solid #ffb80c;
        border-radius: .8vw;
        padding: 0 2.13333vw
    }

    .main-balance-btn .refresh-btn {
        height: 9.59vw;
        padding: 0 6px;
        width: 15%;
        background-image: linear-gradient(-180deg, #414141 0%, #000 100%);
        color: #FFB80C
    }

    .xs-bets,
    .xs-header>.xs-bets,
    .xs-setting {
        height: 5.86667vw;
        background-image: linear-gradient(-180deg, #414141 0%, #000 100%);
        text-align: center
    }

    .main-balance-btn .refresh-btn svg,
    .xs-setting>svg {
        font-size: 22px
    }

    .xs-bets,
    .xs-setting {
        color: #FFB80C;
        line-height: 5.33333vw;
        font-weight: 700;
        border: .26667vw solid #000;
        border-radius: 1.06667vw;
        padding: 1.6vw .86667vw
    }

    .xs-bets img {
        background: url('../images/betcoin.svg') 0 0/contain no-repeat;
        width: 5.33333vw;
        height: 5.5667vw;
        margin-right: 1.33333vw;
        vertical-align: middle
    }

    .xs-bets {
        border-color: #000;
        box-shadow: inset 0 .26667vw 0 0 rgb(255 255 255 / 40%)
    }

    .xs-bets a {
        color: #FFB80C;
        font-weight: 700;
        font-size: 12px
    }

    .sport-blocks .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0 10px 8px
    }

    .header>header {
        padding-top: 8px;
        padding-bottom: 0;
        position: fixed;
        top: 0;
        z-index: 100;
    }
    .dashborad-slider {
        /* margin-top: 64px !importasnt; */
    }
    .header.logged-inn header {
        padding-bottom: 2px;
        position: fixed;
        top: 0;
        height: 75px;
    }
    .news-section{
        padding-bottom: 2px;
        position: fixed;
        top: 55px;
        z-index: 100;
    }
    .header.logged-inn header +div{
        margin-top: 69px;
    }

    header>div {
        padding: 0 4px !important
    }

    .xs-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%
    }

    .xs-header>.xs-bets {
        width: 40%;
        min-width: 18.23333vw;
        max-width: 27.23333vw;
        vertical-align: middle;
        margin: 0 1.03333vw 0 -1px;
        line-height: 5.86667vw
    }

    .xs-header>.xs-sett {
        max-width: 32px;
        width: 20%
    }

    .xs-header>div {
        width: 100%;
        flex: 1 1 auto;
        margin: 0 5px
    }

    .live-count {
        width: 20.2%;
        padding: 1.33333vw
    }

    .live-count .live span {
        line-height: 1.4;
        height: 4.26667vw;
        padding: 0 .8vw;
        border-radius: .8vw 0 0 .8vw
    }

    .live-count .live {
        margin-bottom: 1.6vw;
        height: 4.26667vw;
        line-height: 4.26667vw
    }

    .live-count .sport-count span:first-child {
        line-height: 13px
    }

    .live-count .sport-count {
        margin-bottom: 1.06667vw;
        font-size: 2.66667vw;
        line-height: 3.73333vw
    }

    .live-count .sport-count .show-number {
        padding: 0;
        min-width: 3.73333vw;
        height: 3.73333vw;
        line-height: 3.73333vw;
        font-size: 2.66667vw
    }

    .sport-block {
        max-height: 128px
    }

    .sport-block .bottom-title {
        bottom: -1px;
        border-bottom: 1.12vw solid #ffb80c
    }

    .sport-block .bottom-title .title {
        line-height: 6.4vw;
        flex: 1;
        text-indent: 1.33333vw;
        color: #fff;
        font-weight: 700
    }

    .sport-block .bottom-title .play-now::before {
        width: 7.3958vmax;
        left: -36px
    }

    .inplay-parent {
        width: 100%;
        margin: 0 0 70px !important
    }

    .tab {
        min-width: 98% !important;
        width: 98% !important;
        margin: 0 auto
    }

    .headerInplay.tab button.MuiTab-textColorInherit.Mui-selected {
        border-right: 0
    }

    .tab button.MuiTab-textColorInherit.Mui-selected {
        color: #172832;
        background: #fff
    }

    .tab button.MuiTab-root {
        background: #172832;
        color: #fff
    }

    .tab button {
        border-left: 1px solid #fff;
        padding: 8px 0
    }

    .inplay-tabs table {
        border-bottom: 3px solid #000
    }

    .inplay-xs-title>img,
    .iplay-now-head>img {
        width: 3.33333vw;
        margin-right: 1.33333vw;
        vertical-align: middle;
        background-color: #1876b2;
        padding: 2px;
        border-radius: 4px
    }

    a.inplay-last-icon {
        background-image: none !important;
        background-position: -398px -57px;
        height: 21px;
        width: 22px !important;
        display: block;
        margin: auto
    }

    .inplay-xs-title>span,
    .iplay-now-head>span {
        font-size: 3.2vw;
        line-height: 1.1;
        vertical-align: middle
    }

    .inplay-xs-title {
        font-size: 3.2vw
    }

    .table_first_row .text_left-in-play {
        font-size: 4vw;
        display: flex;
        align-items: center;
        line-height: 1.6
    }

    .inplay-now-xsblock>svg {
        margin-left: 12px
    }

    .inplay-now-xsblock a {
        color: #2789ce
    }

    .inplay-tom-xsfooter {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .inplay-tom-xsfooter .inplay-last-icon {
        margin: 0 5px 0 0 !important
    }

    .circle-size.circle-inplay {
        color: #6bbd11 !important
    }

    .circle-size {
        color: #c5d0d8 !important;
        font-size: 15px !important;
        vertical-align: middle;
        margin-right: 1.33333vw
    }

    .cricket-section .tbl-top-head {
        background-image: linear-gradient(-180deg, #2e4b5e 0, #243a48 82%);
        color: #fff;
        text-align: center;
        font-size: 3.73333vw
    }

    .cricket-section .xs-padding,
    .soccer-section .xs-padding,
    .tennis-section .xs-padding {
        padding: 0 !important;
        display: block !important
    }

    .tbl_body .table_first_row {
        padding: 1.6vw 0 1.86667vw 2.26667vw !important
    }

    .cricket_tbl_body .tags,
    .soccer_tbl_body .tags,
    .tennis_tbl_body .tags {
        margin-top: 5px;
        margin-bottom: -5px
    }

    .cricket_tbl_body .tags>img,
    .soccer_tbl_body .tags>img,
    .tennis_tbl_body .tags>img {
        margin-right: 3px
    }

    .cricket_tbl_body .tags>span,
    .soccer_tbl_body .tags>span,
    .tennis_tbl_body .tags>span {
        font-size: 3.2vw;
        vertical-align: super;
        color: #777
    }

    .xs-multi-marketing {
        background: #fff;
        border: 1px solid #7e97a7;
        border-radius: 1.6vw;
        color: #7e97a7;
        font-size: 4.26667vw;
        margin: 5.33333vw 5.66667vw;
        padding: 2.66667vw 1.86667vw 5.33333vw
    }

    .xs-multi-marketing>h3 {
        display: flex;
        background-image: none;
        font-size: 5.33333vw;
        color: #7e97a7;
        border-bottom: 1px solid #e0e6e6;
        margin-bottom: 1.33333vw;
        padding: 1.86667vw 0;
        align-items: center;
        justify-content: center;
        text-align: center
    }

    .xs-multi-marketing>p {
        text-align: center
    }

    .xs-multi-marketing h3::before {
        content: '';
        width: 8.26667vw;
        height: 8.53333vw;
        background: url("../images/icon-nodata.svg") 0 0/contain no-repeat;
        margin-right: 1.33333vw
    }

    .cricket-banner>img,
    .soccer-banner>img,
    .tennis-banner>img {
        height: 160px
    }

    .special_bet .lg-none {
        display: table-cell !important;
        background: #3b5160
    }

    .cricket-fancy-head .fancy-header-text>span img,
    .ipl-xs-head>div img,
    .special_bet>.lg-none>a {
        vertical-align: middle
    }

    .special_bet>.lg-none>span {
        font-size: 3.46667vw !important;
        vertical-align: middle
    }

    .fullmarket_tbl_body .MuiTableCell-root {
        font-size: 3.46667vw
    }

    .fullmarket_tbl_body .table_first_row {
        font-size: 12px;
        height: 32px
    }

    .cricket-banner {
        border-top: 1px solid #000
    }

    .back-block .back-box,
    .lay-block .lay-box {
        height: 28px;
        line-height: 28px
    }

    .back-block,
    .lay-block {
        height: 28px;
        padding: 2px 0
    }

    .back-block>span,
    .lay-block>span {
        width: 60px
    }

    .back-block {
        background: #72bbef
    }

    .lay-block {
        background: #f0c0cb
    }

    .tab-fancy button {
        padding: 4px 8px !important
    }

    .tab-fancy .MuiTabs-centered {
        justify-content: start;
        background: #087989
    }

    .tab-fancy button.MuiTab-root {
        background: #087989;
        color: #fff;
        font-size: 12px
    }

    .tab-fancy button.MuiTab-root::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 5.33333vw;
        background-color: rgba(255, 255, 255, .4);
        transform: translate(-50%, -50%)
    }

    .table_first_row .text_left-in-ipl {
        font-size: 12px;
        padding-left: 8px
    }

    .financial_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter {
        width: 20%;
        font-size: 12px
    }

    .cricket-fancy-head>span {
        padding: 4px 16px 4px 32px;
        position: relative
    }

    .cricket-fancy-head .fancy-header-text>img {
        vertical-align: middle;
        width: 18px;
        padding: 5px 4px;
        position: absolute;
        left: 0;
        top: 0;
        background: #182832
    }

    .match-btn span a,
    .pin-toggle a.inplay-last-icon,
    .sport-xs-tab header button img {
        background-size: contain;
        background-repeat: no-repeat
    }

    .financial_tbl_body td {
        font-size: 12px
    }

    .multi-head .tbl_head {
        background: #172832
    }

    .multi-head .tbl_head th {
        padding: 4px 2px !important;
        position: relative;
        border: 0
    }

    .multi-head .tbl_head th span.ipl-header-text {
        vertical-align: middle;
        padding-left: 32px;
        color: #fff
    }

    .multi-head .tbl_head th a {
        position: absolute
    }

    .multi-head .tbl_head svg {
        font-size: 20px;
        padding: 0;
        vertical-align: middle;
        color: #fff
    }

    .right-arrow {
        background: #3b5160;
        border-radius: 4px
    }

    .match-block {
        margin-bottom: 16px;
        border-bottom: 2px solid #7e97a7
    }

    .match-block-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #243a48;
        background-image: linear-gradient(-180deg, #2e4b5e 0, #243a48 82%);
        padding: 6px;
        line-height: 1;
        color: #fff
    }

    .match-block-header h5 {
        margin: 0;
        font-size: 12px
    }

    .match-block-header span {
        font-size: 12px;
        padding-right: 4px
    }

    .match-block-header span img {
        vertical-align: middle;
        border-radius: 5px
    }

    .match-btn {
        /* margin-top: 18px; */
        /* margin-top: 100px; */
        background-color: #e0e6e6;
        display: flex;
        justify-content: center;
        position: relative
    }

    .match-btn span {
        width: 24.66667vw;
        display: inline-flex
    }

    .match-btn span a {
        width: 110px;
        height: 7.46667vw;
        text-align: center;
        font-size: 3.2vw;
        line-height: 7.46667vw;
        font-weight: 700;
        color: #fff;
        letter-spacing: .2px
    }

    .match-btn span a img {
        width: 8px !important;
        vertical-align: middle
    }

    .match-btn span a svg {
        font-size: 16px;
        vertical-align: middle
    }

    .match-btn .btn-pin {
        background-image: url('../images/match-odd-left.png')
    }

    .match-btn .btn-refresh {
        background-image: url('../images/match-odd-right.png')
    }

    .sport-xs-tab .MuiTabScrollButton-root.MuiTabs-scrollButtons {
        width: 2px !important;
        display: none
    }

    .sport-xs-tab>header {
        background: linear-gradient(-180deg, #ffcc2e 0%, #ffb80c 71%);
        width: 88%;
        float: left
    }

    .sport-xs-tab .MuiTabs-root {
        min-height: unset !important
    }

    .sport-xs-tab header button {
        min-width: auto;
        width: max-content;
        min-height: unset;
        font-weight: 700;
        text-transform: unset;
        font-size: 16px;
        padding: 6px 8px;
        color: #000
    }

    .sport-xs-tab header button img {
        margin-bottom: 0 !important;
        margin-right: 4px;
        width: 18px;
        height: 18px
    }

    .sport-xs-tab .MuiTab-wrapper {
        flex-direction: row !important;
        font-size: 12px;
        padding: 0
    }

    .sport-xs-tab button.Mui-selected {
        background-image: linear-gradient(-180deg, #464646 15%, #121212 100%);
        color: #ffb80c;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px
    }

    .sport-xs-tab button.cricket-img img {
        background-image: url("../images/cricket-ball-black.svg")
    }

    .sport-xs-tab button.Mui-selected.cricket-img img {
        background-image: url("../images/cricket-ball.svg")
    }

    .sport-xs-tab button.soccer-img img {
        background-image: url("../images/soccer-ball-black.svg")
    }

    .sport-xs-tab button.Mui-selected.soccer-img img {
        background-image: url("../images/soccer-ball.svg")
    }

    .sport-xs-tab button.tennis-img img {
        background-image: url("../images/tennis-ball-black.svg")
    }

    .sport-xs-tab button.Mui-selected.tennis-img img {
        background-image: url("../images/tennis-ball.svg")
    }

    .search-xs {
        min-width: 14% !important;
        width: 14% !important;
        min-height: 45px;
        background-image: linear-gradient(180deg, #525252 0, #2d2d2d 100%);
        border-radius: 0 !important
    }

    .search-xs svg {
        font-size: 26px;
        color: #fff
    }

    .MuiPaper-elevation1 {
        box-shadow: none !important
    }

    .soccer-section .tbl-top-head,
    .tennis-section .tbl-top-head {
        background-color: var(--darkblue);
        color: #fff;
        text-align: center
    }

    .cricket_tbl_body,
    .soccer_tbl_body,
    .tennis_tbl_body {
        border-bottom: 2px solid #000
    }

    .ipl-xs-section {
        padding: 0 !important;
        margin: 0 !important;
        width: auto !important;
        font-size: 22.6px
    }

    .ipl-header .pin-icon a {
        display: inline-block;
        width: 24px !important;
        background-position: -393px -77px
    }

    .pin-toggle a.inplay-last-icon {
        width: 6.66667vw !important;
        height: 6.66667vw;
        background-image: url('../images/pin-off.svg') !important;
        background-position: unset
    }

    .pin-toggle input:checked+a {
        background-image: url('../images/green-pin-off.svg') !important
    }

    .bet-slip-bar p {
        font-size: 3.73333vw;
        font-weight: 700
    }

    .bet-slip-bar>div {
        justify-content: center
    }

    .ipl-header {
        padding: 10px !important
    }

    .ipl-header .ipl-header-text {
        font-size: 13px;
        font-weight: 700;
        display: inline-block
    }

    .ipl-header .ipl-header-text small {
        display: block;
        line-height: 1;
        font-size: 7px;
        color: #7e97a7
    }

    .ipl-section .ipl-tbl-head {
        background-color: #fff
    }

    .ipl-tbl-head .ipl-middle-head {
        font-weight: 700
    }

    .ipl-section .tbl_head th {
        padding: 8px 8px 3px !important
    }

    .ipl-header .pin-icon {
        vertical-align: top
    }

    .ipl-header .pin-icon img {
        width: 24px;
        margin-right: 6px
    }

    .ipl_tbl_body .text_left-in-ipl {
        font-size: 13.52px !important;
        padding-left: 8px
    }

    .xs-ipl-block>div {
        font-size: 12.52px !important
    }

    .ipl_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter.xs-ipl-block span {
        font-size: 11.52px !important;
        font-weight: 400
    }

    .ipl_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter {
        width: 19%;
        font-size: 20.52px !important
    }

    .ipl_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter span {
        margin-top: 2px;
        font-size: 16px !important
    }

    .ipl-tbl-head th {
        padding: 8px 0 4px;
        position: relative
    }

    .ipl-xs-head>a {
        position: absolute;
        z-index: 1;
        width: 9.4vw;
        height: 10.33333vw;
        background: #e0e6e6;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .ipl-xs-head>a::after {
        position: absolute;
        left: 9.4vw;
        content: '';
        width: 0;
        height: 0;
        border-top: 0 solid transparent;
        border-bottom: 11.33333vw solid transparent;
        border-left: 1.86667vw solid #e0e6e6
    }

    .ipl-xs-head>div {
        padding-left: 12.26667vw;
        display: flex;
        align-items: center
    }

    .winner-th {
        background-color: #e0e6e6
    }

    a.winner {
        color: #1e1e1e;
        background-image: linear-gradient(-180deg, #ccc 15%, #e8e8e8 100%);
        line-height: 9.06667vw;
        font-weight: 700;
        border: 1px solid rgba(51, 51, 51, .3);
        border-radius: 4.8vw;
        padding: 2vw 2.4vw;
        font-size: 12px
    }

    .coin-list,
    .coin-list a {
        background-image: linear-gradient(-180deg, #32617f 20%, #1f4258 91%)
    }

    .matched-match {
        display: flex;
        flex-direction: column;
        padding-left: 8px
    }

    .matched-match>span {
        padding: 0;
        line-height: 1.1;
        color: #1e1e1e;
        font-weight: 600
    }

    .balance-table,
    .statement-table {
        min-width: auto !important
    }

    .balance-table .table_head th,
    .statement-table .table_body td,
    .statement-table .table_body th,
    .statement-table .table_head th {
        font-size: 8px;
        padding: 2px
    }

    .betbarter-column .middle-bar,
    .betbazar-column .middle-bar,
    .betbuz-column .middle-bar,
    .bethive-column .middle-bar,
    .setsport-column .middle-bar,
    .sky247-column .middle-bar {
        background: 0 0;
        margin-top: 0
    }

    .bet-popup .header,
    .set-popup .header {
        background-color: #222 !important;
        height: 45px
    }

    .middle-bar {
        align-self: flex-start
    }

    .middle-bar-content {
        margin-bottom: auto;
        margin-top: 16px
    }

    .align-center,
    .align-start {
        justify-content: flex-end
    }

    .text-align-center,
    .text-align-left {
        justify-content: flex-end;
        right: 16px;
        bottom: 16px
    }

    .align-center .middle-bar-content,
    .align-start .middle-bar-content {
        align-self: flex-end
    }

    .text-align-left {
        left: auto
    }

    .text-align-right {
        justify-content: flex-end;
        right: 16px;
        bottom: 16px
    }

    .btn {
        padding: 8px 24px;
        font-size: 1em
    }

    .announce-header h1 {
        font-size: 15px
    }

    .saga-modal .MuiDialog-paperWidthSm {
        min-width: 90% !important
    }

    .ranger-bar div.max,
    .ranger-bar div.start {
        width: 15%;
        font-size: 12px;
        line-height: 53px
    }

    .ranger-bar div.range {
        width: 70%
    }

    .saga-modal .awcTitle {
        font-size: 12px;
        display: flex
    }

    #customized-dialog-title {
        padding: 6px 12px;
        background-image: linear-gradient(-180deg, #414141 0, #000 100%);
        color: #ffb80c
    }

    .range-show .left-saga,
    .range-show .right-saga {
        padding: 6px 12px;
        font-size: 12px;
        color: #000
    }

    .range-show .left-saga p,
    .range-show .right-saga p {
        margin: 0;
        font-weight: 700;
        font-size: 14px
    }

    .range-parent {
        padding: 12px 0 !important
    }

    .ranger-bar {
        border-radius: 0
    }

    .xs-account-tabs button {
        min-width: 100%;
        min-height: unset;
        border-bottom: 1px solid #e0e6e6;
        font-weight: 700;
        text-transform: none;
        padding: 2px 12px;
        opacity: 1
    }

    .xs-account-tabs button .MuiTab-wrapper {
        flex-direction: row-reverse;
        justify-content: space-between;
        color: #2789ce
    }

    .xs-account-tabs button.MuiTab-labelIcon svg {
        color: #333;
        font-size: 16px;
        padding: 3px;
        border: 1px solid #e0e6e6;
        border-radius: 4px
    }

    .xs-account-tabs .acc-logout {
        margin: 16px 0 !important;
        border-radius: 0;
        background-image: linear-gradient(-180deg, #e93522 0, #be2414 100%);
        border: 1px solid #8a0011;
        min-height: 48px;
        font-size: 16px;
        text-transform: uppercase
    }

    .set-popup .header {
        padding-top: 0;
        padding-bottom: 0
    }

    .popup-setting-body {
        padding-top: 13px;
        top: 32px
    }

    .bet-list,
    .btn-list {
        padding: 0 1.6vw 2.66667vw
    }

    .set-popup .header>div {
        position: relative;
        top: 0
    }

    .bet-popup .xs-bets-popup div>img,
    .set-popup .xs-setting-popup div>img {
        background: url('../images/betcoin.svg') 0 0/contain no-repeat;
        width: 5.33333vw;
        height: 5.5667vw;
        margin-right: 1.33333vw;
        vertical-align: middle
    }

    .set-popup .xs-setting-popup {
        color: #ffb80c;
        display: flex
    }

    .set-popup .xs-setting-popup>div {
        height: 40px
    }

    .bet-popup .xs-bets-popup>div button,
    .set-popup .xs-setting-popup>div button {
        padding: 11px 6px;
        border-left: 1px solid #444;
        border-radius: 0
    }

    .bet-popup .xs-bets-popup>div button svg,
    .set-popup .xs-setting-popup>div button svg {
        font-size: 16px
    }

    .set-popup .header-name svg {
        position: relative;
        top: 7px;
        padding-right: 5px
    }

    .bet-popup .header-name,
    .set-popup .header-name {
        color: #ffb80c;
        align-items: center;
        font-size: 4vw;
        font-weight: 700;
        line-height: 2.6;
        padding: 0 1.86667vw;
        align-items: center
    }

    .bet-popup .header>div {
        position: relative;
        top: -8px
    }

    .bet-popup .xs-bets-popup {
        color: #ffb80c;
        display: inline-block
    }

    .bet-popup .xs-bets-popup>div {
        height: 40px;
        display: inline-block
    }

    .bet-popup .xs-bets-popup>div:first-child {
        width: 90%;
        text-align: left
    }

    .bet-popup .xs-bets-popup>div:last-child {
        text-align: right
    }

    .popup-setting-body .header-set {
        background-image: linear-gradient(-180deg, #2e4b5e 0, #243a48 82%);
        color: #fff;
        font-size: 3.73333vw;
        line-height: 2.2;
        padding: 0 10px
    }

    .popup-setting-body .setting-first-list {
        padding: 1.86667vw 0 0 1.86667vw
    }

    .popup-setting-body .setting-first-list span {
        flex: 1;
        padding: 0 1.86667vw 1.86667vw;
        align-items: center;
        font-size: 13px;
        font-weight: 400
    }

    .popup-setting-body .userCoin {
        width: 26.66667vw;
        margin: 0 0 1.86667vw 1.33333vw;
        text-align: right;
        position: relative;
        background: #fff;
        border: 1px solid #aaa;
        box-shadow: inset 0 .53333vw 0 0 rgb(0 0 0 / 10%);
        border-radius: 1.6vw;
        color: #1e1e1e;
        font-size: 4vw;
        padding: 2.66667vw 1.86667vw;
        -webkit-appearance: none
    }

    .popup-setting-body .setting-stack input {
        display: none;
        width: 80%;
        margin: auto;
        border-radius: 6px;
        box-shadow: inset 0 .53333vw 0 0 rgb(0 0 0 / 10%);
        border: 1px solid;
        padding: 12px 8px;
        font-size: 4vw;
        text-align: center;
        color: #1e1e1e
    }

    .popup-setting-body .setting-stack a,
    .popup-setting-body .stake-custom-edit a {
        padding: 8px 0;
        background-image: linear-gradient(-180deg, #fff 0, #eee 89%);
        color: #1e1e1e;
        font-size: 4vw;
        font-weight: 700
    }

    .popup-setting-body .setting-stack input:nth-child(4),
    .popup-setting-body .setting-stack input:nth-child(8) {
        margin-right: 0
    }

    .popup-setting-body .setting-stack a.selectss,
    .popup-setting-body .setting-stack input:checked+a {
        background: #444 !important;
        border-color: #000 !important;
        color: #ffb80c !important
    }

    .popup-setting-body .setting-stack a {
        border: 1px solid #aaa;
        border-radius: 1.6vw
    }

    .popup-setting-body .stake-custom-edit a {
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        width: 100%
    }

    .popup-setting-body .edit-settings {
        width: 100%;
        line-height: 2.6;
        font-weight: 700;
        padding: 0 !important;
        color: #243a48 !important;
        background: rgba(197, 208, 215, .4) !important;
        border: .26667vw solid #7e97a7 !important;
        box-shadow: inset 0 .53333vw 0 0 rgb(255 255 255 / 80%) !important;
        border-radius: 1.6vw !important
    }

    .popup-setting-body .setting-button span {
        padding: 10px;
        font-size: 13px
    }

    .popup-setting-body .setting-button .MuiTouchRipple-root {
        border: 1px solid #aaa;
        border-radius: 1.6vw
    }

    .bet-body-popup {
        margin-top: 35px !important
    }

    .bet-body-popup link td {
        padding: 7px !important
    }

    .arrow-icon a.inplay-last-icon {
        background-image: url("../images/left-s1873922290.png") !important;
        background-position: 59% -2379px;
        height: 21px;
        width: 16px !important;
        display: block;
        margin: auto
    }

    .xs-bet-slip {
        background-color: #dceaf4;
        border-bottom: 1px solid #7e97a7 !important
    }

    .bet-list {
        display: flex
    }

    .bet-list>div {
        width: 100%;
        margin: 0 .8vw;
        display: flex;
        flex-direction: column
    }

    .bet-list .typed {
        color: #999;
        background-color: #dcdcdc;
        width: 100%;
        box-shadow: none;
        border: 1px solid #bbb;
        font-size: 4vw;
        border-radius: 1.6vw
    }

    .bet-list p {
        font-size: 2.93333vw;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 0
    }

    .input-num>div {
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        display: flex
    }

    .input-num>div a {
        height: 10.93333vw;
        line-height: 10.93333vw;
        width: 12vw;
        background-image: linear-gradient(-180deg, #fff 0, #eee 89%);
        display: flex;
        justify-content: center;
        align-items: center
    }

    .input-num>div span {
        display: flex;
        color: #1e1e1e;
        background-color: #fff0ca;
        box-shadow: inset 0 .26667vw 1.33333vw rgb(161 128 45 / 60%);
        border-color: #be7809;
        width: 50%;
        align-items: center;
        justify-content: center;
        font-size: 4vw;
        font-weight: 700
    }

    .input-num>div a svg {
        font-weight: 700;
        font-size: 28px;
        color: #1f72ac
    }

    .coin-list {
        margin-bottom: 1.86667vw;
        display: flex
    }

    .coin-list a {
        width: 100%;
        border-right: 1px solid rgba(255, 255, 255, .15);
        line-height: 2.46;
        color: #fff;
        text-align: center;
        font-size: 3.46667vw
    }

    .keyboard-wrap .btn-tel a,
    .keyboard-wrap>a {
        line-height: 10.4vw;
        border: 1px solid #aaa;
        display: flex;
        background-color: #fff
    }

    .keyboard-wrap {
        display: flex;
        border-top: 1px solid #aaa;
        margin-bottom: 1.86667vw
    }

    .keyboard-wrap .btn-tel {
        display: flex;
        flex: 1;
        flex-wrap: wrap
    }

    .keyboard-wrap .btn-tel a {
        flex: 1 1 15.66667%;
        font-size: 4vw;
        color: #1e1e1e;
        border-width: 0 0 1px 1px;
        align-items: center;
        justify-content: center
    }

    .keyboard-wrap>a {
        flex: 0 1 14.66667vw;
        color: #1e1e1e;
        border-width: 0 0 1px 1px;
        align-items: center;
        justify-content: center
    }

    .btn-list {
        display: flex;
        flex-wrap: wrap
    }

    .acceptodds,
    .bets-bookmaker .bets-selections-head dt {
        padding: 0 1.86667vw
    }

    .btn-list a {
        flex: 1;
        margin: 0 .8vw;
        height: 10.93333vw;
        background-image: linear-gradient(-180deg, #fff 0, #eee 89%);
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw;
        font-weight: 700;
        line-height: 2.6;
        color: #1e1e1e;
        text-align: center
    }

    .acceptodds,
    .acceptodds a {
        height: 8.53333vw;
        font-size: 3.46667vw;
        display: flex
    }

    .btn-list a.placebtn {
        background-image: linear-gradient(-180deg, #464646 15%, #121212 100%);
        border-color: #000;
        color: #ffb80c
    }

    .acceptodds {
        background: #c7dbe9;
        align-items: center
    }

    .acceptodds a {
        width: 100%;
        align-items: center;
        color: #1e1e1e;
        margin-right: 1.86667vw
    }

    .MuiTabs-flexContainer {
        padding-top: 0
    }

    .sport-xs-tab button.Mui-selected,
    .sport-xs-tab header button {
        overflow: visible
    }

    .sport-xs-tab>header {
        padding-top: 7px
    }

    .acceptodds a:before {
        width: 4.8vw;
        height: 4.8vw;
        background-color: #fff;
        box-shadow: inset 0 .53333vw 0 0 rgb(0 0 0 / 40%);
        border-radius: 1.06667vw;
        margin-right: 1.86667vw;
        content: ""
    }

    .acceptodds input:checked+a:before {
        background: url('../images/checkef.svg') center/2.93333vw 2.93333vw no-repeat #ffb867 !important
    }

    .tab-sportsname header button {
        overflow: visible;
        padding: 8px 0;
        margin-right: 2%;
        width: 31%
    }

    .tab-sportsname header button:nth-child(2) {
        width: 33%
    }

    .tab-sportsname button.search-xs {
        top: 4px;
        min-width: 12% !important;
        width: 10% !important
    }

    .cricket_tbl_body .text_left-in-play,
    .soccer_tbl_body .text_left-in-play,
    .tennis_tbl_body .text_left-in-play {
        padding-bottom: 6px
    }

    .cricket_tbl_body .inplay-xs-title,
    .soccer_tbl_body .inplay-xs-title,
    .tennis_tbl_body .inplay-xs-title {
        padding-top: 5px
    }

    .bets-bookmaker {
        display: block
    }

    .bets-bookmaker .bets-selections-head {
        height: 5.86667vw;
        line-height: 5.86667vw;
        display: flex
    }

    .bets-selections-head {
        line-height: 1.1
    }

    .bets-selections,
    .bets-selections-head,
    .trade,
    .trade-2 {
        position: relative;
        min-height: 11.2vw;
        border-bottom: 1px solid #7e97a7
    }

    .bets-bookmaker .bets-selections-head dd {
        border-right: none;
        padding: 0
    }

    .bets-selections-head dd {
        width: 18.66667vw;
        font-weight: 700;
        padding: 1.86667vw 1.86667vw .8vw;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        display: flex
    }
}

@media all and (max-width:1200px) {

    .inner-text .big-text,
    .inner-text .small-text {
        margin: 0;
        font-size: 1.2em
    }

    .betbarter-column {
        background-image: url(../images/KV-pic-9wicketslive.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top left
    }

    .betbazar-column,
    .bethive-column,
    .setsport-column {
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat
    }

    .bethive-column {
        background-image: url(../images/bgbaji.png)
    }

    .setsport-column {
        background-image: url(../images/bg_popup.png)
    }

    .betbazar-column {
        background-image: url(../images/Background.png)
    }

    .sky247-column {
        background-image: url(../images/betbuzbg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top right
    }

    .brand-column {
        width: calc(100% / 1);
        height: calc(100vh / 3);
        background-color: gray;
        flex-wrap: wrap;
        position: relative
    }

    .brand-column>div {
        margin-top: calc(100vh/2)
    }

    .characters {
        display: none
    }

    .align-center .middle-bar-content,
    .align-end .middle-bar-content,
    .align-start .middle-bar-content {
        padding: 0 16px 16px 0;
        align-items: flex-end
    }

    .text-align-center .inner-text,
    .text-align-left .inner-text {
        text-align: right
    }

    .btn-betbarter,
    .btn-bethive {
        color: #000;
        background-color: #fff;
        text-shadow: none
    }

    .btn-sky247 {
        color: #fff;
        background-color: #000;
        text-shadow: none
    }
}

.lcolor {
    background-color: #f2e5e8 !important
}

.bcolor {
    background-color: #dceaf4 !important
}

.l-dark-color {
    background-color: #ebd5db !important
}

.b-dark-color {
    background-color: #c7dbe9 !important
}

.going-inplay-cricle {
    color: grey !important;
    font-size: 15px !important;
    vertical-align: middle;
    margin-right: 1.33333vw
}

.game-fancy img {
    background-color: #0a92a5 !important
}

@media screen and (min-width:320px) and (max-width:476px) {
    .dashboard .sport-blocks .MuiGrid-root.prx-4.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-3 {
        width: 48%;
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0
    }

    .sport-blocks .sport-block {
        max-height: 160px;
        overflow: hidden;
        height: 137px
    }

    .sport-blocks .sport-block img {
        object-fit: cover
    }

    .sport-block .bottom-title .title {
        font-size: 3.73333vw
    }
}

@media screen and (max-width:600px) and (min-width:481px) {
    .login-index .MuiButton-label {
        width: auto;
        font-size: 16px
    }

    .btn-signup span.MuiButton-label {
        font-size: 16px
    }

    .dashboard .sport-blocks .MuiGrid-root.prx-4.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-3 {
        min-height: 140px
    }
}

@media screen and (device-width:320px) and (device-height:480px) and (orientation:portrait) {
    .login-index .MuiButton-label {
        width: auto;
        font-size: 14px
    }

    .btn-signup span.MuiButton-label {
        font-size: 14px
    }
}

@media screen and (device-width:481px) and (device-height:568px) and (orientation:portrait) {
    .login-index .MuiButton-label {
        width: auto;
        font-size: 14px !important
    }

    .btn-signup span.MuiButton-label {
        font-size: 14px !important
    }
}

@media screen and (device-width:569px) and (device-height:768px) and (orientation:portrait) {
    .login-index .MuiButton-label {
        width: auto;
        font-size: 20px !important
    }

    .btn-signup span.MuiButton-label {
        font-size: 20px !important
    }
}

@media screen and (max-width:480px) and (min-width:400px) {
    .sport-block .bottom-title .play-now {
        text-indent: -1.56667vw;
        width: 17.13333vw;
        line-height: 7.46667vw;
        font-size: 2.93333vw;
        height: 6.544vw;
        text-indent: 1.43333vw
    }

    .sport-block .bottom-title .play-now::before {
        height: 6.4955vw;
        width: 6.3958vw;
        left: -20px
    }

    .sport-block .bottom-title .title {
        font-size: 3.73333vw
    }
}

@media screen and (min-width:1350px) {
    #root header>div {
        max-width: calc(100% - 40px);
        margin: 0 auto;
        min-width: 1350px;
        width: 100%
    }
}

@media screen and (min-width:990px) {
    #root header>div {
        padding-left: 0;
        padding-right: 0
    }

    div.navbar.onlynavbar {
        margin-bottom: 0
    }

    body {
        font-family: Tahoma, Helvetica, sans-serif
    }

    .header>header .MuiToolbar-gutters .makeStyles-search-9 input,
    .navMenu span.MuiTypography-root a {
        font-size: 12px
    }

    .navbar .nav-right>span.MuiTypography-root {
        padding: 6px 4px 4px;
        line-height: 22px;
        position: relative
    }

    .logged-out header>a {
        vertical-align: text-top
    }

    .header>header {
        padding-bottom: 0
    }

    .header>header .MuiToolbar-gutters .makeStyles-search-9 {
        margin-left: 15px;
        top: -8px
    }

    .navbar>header {
        box-shadow: none
    }

    svg.MuiSvgIcon-root.theme-yellow.xs-none {
        width: 20px
    }

    .onlynavbar .dark-menu {
        padding-top: 2px !important;
        padding-bottom: 2px !important
    }

    .navMenu>span.MuiTypography-root {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .dashboard .sport-blocks .MuiGrid-grid-md-3 .sport-block,
    .dashboard .sport-blocks .MuiGrid-grid-md-6 .sport-block {
        height: 185px;
        overflow: hidden
    }

    .sport-blocks .sport-block .bottom-title .play-now {
        z-index: 90999999999;
        width: 73px;
        text-indent: 0
    }

    .sport-blocks .sport-block .bottom-title .play-now::before {
        z-index: -1;
        left: -48px
    }

    .sport-blocks .sport-block .bottom-title .title {
        font-size: 17px
    }
}

@media screen and (min-width:700px) {
    .header.logged-out button.MuiButtonBase-root {
        font-size: 12px
    }

    .sport-block .bottom-title .play-now {
        width: 80px;
        line-height: 30px;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        text-indent: 15px;
        font-size: 12px;
        font-weight: 700;
        color: #000 !important
    }

    .sport-block .bottom-title .play-now::before {
        position: absolute;
        z-index: 0;
        bottom: 0;
        right: 0;
        width: 64px;
        height: 100%;
        content: "";
        left: -50px
    }
}

.sport-block .bottom-title .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media screen and (max-width:400px) and (min-width:320px) {
    .sport-block .bottom-title .title {
        font-size: 3.73333vw
    }

    .sport-block .bottom-title .play-now::before {
        width: 12.3958vw;
        left: -35px;
        height: 6.4vw
    }

    .tab-sportsname header button span {
        font-size: 11px !important
    }
}

@media screen and (max-width:600px) and (min-width:400px) {
    .sport-blocks .MuiGrid-grid-xs-12 .sport-block {
        height: 100%
    }
}

@media screen and (max-width:600px) and (min-width:477px) {
    .sport-blocks .sport-block {
        max-height: 160px;
        overflow: hidden;
        height: 137px
    }

    .sport-block .bottom-title .play-now::before {
        height: 6.5988vw;
        left: -29px
    }

    .sport-block .bottom-title .title {
        font-size: 3.73333vw
    }
}

@media screen and (max-width:400px) and (min-width:370px) {
    .sport-block .bottom-title .play-now::before {
        left: -40px;
        height: 6.45vw
    }
}

.signup-div {
    word-spacing: 11.2px;
    padding: 21px;
    font-size: 17px
}

.signuphead a {
    padding: 15px;
    color: green
}

.signuphead {
    padding: 15px
}

.signuphead a svg {
    font-size: 41px
}

.signup-div a {
    margin: 10px
}